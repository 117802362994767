footer {
  color: #797979;
  font-size: 0.8rem;
}

.footer-inner {
  padding: 0.5rem 0 1rem;
}

.email-link {
  position: relative;
  color: #797979;
  text-decoration: none;
}

.email-link::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #e08283;
  transition: 0.2s ease-out;
  transform: translateZ(0) scaleX(0);
  transform-origin: left;
}

.email-link:hover {
  color: #e08283;
  transition: 0.2s ease-out;
}

.email-link:hover::before {
  transform: translateZ(0) scaleX(1);
}
