body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: 'Noto Serif', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media screen and (max-width: 1095px) {
  body {
    font-size: 16px;
  }
}

::selection {
  background-color: #f3b1b2;
}