.home {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 3vh 0;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-clip: content-box;
}

@media screen and (max-width: 600px) {
  .home-hero {
    width: auto;
    height: 80%;
    transform: translate(-10%);
  }
}
